import axios from "axios";
import store from "../store/index";

export default class ApartmentService {
  static async addApartment(apartment) {
    return await axios.post(
      `${process.env.VUE_APP_API_URL}/apartments/add`,
      {
        apartment,
      },
      { headers: { Authorization: `Bearer ${store.getters.getToken}` } }
    );
  }
  static async updateApartment(id, apartment) {
    return await axios.put(
      `${process.env.VUE_APP_API_URL}/apartments/${id}`,
      {
        apartment,
      },
      { headers: { Authorization: `Bearer ${store.getters.getToken}` } }
    );
  }

  static async delete(id) {
    return await axios.delete(
      `${process.env.VUE_APP_API_URL}/apartments/${id}`,
      { headers: { Authorization: `Bearer ${store.getters.getToken}` } }
    );
  }
}
