<template>
  <div class="wrapper">
    <header class="move-up">
      <div>
        <h2>Panel administracyjny - <span>Dodawanie mieszkania</span></h2>
      </div>
    </header>
    <section class="wrapper">
      <base-card class="" :columns="12">
        <template #default>
          <div class="wrapper">
            <form @submit.prevent="saveApartment">
              <div class="form-group">
                <div class="form-control">
                  <input
                    type="text"
                    v-model="apartment.title"
                    placeholder="Tytuł"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-control">
                  <input
                    type="text"
                    v-model="apartment.city"
                    placeholder="Miasto"
                  />
                </div>
                <div class="form-control">
                  <input
                    type="text"
                    v-model="apartment.district"
                    placeholder="Dzielnica"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-control">
                  <input
                    type="number"
                    v-model.number="apartment.rooms"
                    placeholder="Liczba pokoi"
                  />
                </div>
                <div class="form-control">
                  <input
                    type="text"
                    v-model.number="apartment.surface"
                    placeholder="Powierzchnia"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-control">
                  <input
                    type="number"
                    v-model.number="apartment.price"
                    placeholder="Cena"
                  />
                </div>
                <div class="form-control">
                  <input
                    type="text"
                    v-model.number="apartment.rent"
                    placeholder="Czynsz"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-control">
                  <input
                    type="number"
                    v-model.number="apartment.floorNumberInBuilding"
                    placeholder="Liczba pięter w budynku"
                  />
                </div>
                <div class="form-control">
                  <input
                    type="number"
                    v-model.number="apartment.floor"
                    placeholder="Piętro"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-control">
                  <input
                    type="text"
                    v-model="apartment.buildingType"
                    placeholder="Typ budynku"
                  />
                </div>
                <div class="form-control">
                  <input
                    type="date"
                    v-model="apartment.avaiableFrom"
                    placeholder="Dostępne od"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-control">
                  <input
                    type="number"
                    v-model.number="apartment.deposit"
                    placeholder="Kaucja"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-control">
                  <input
                    type="text"
                    v-model="apartment.link"
                    placeholder="Link do aukcji"
                  />
                </div>
              </div>

              <div class="form-group">
                <div class="form-control">
                  <button type="submit" class="btn base">Zapisz</button>
                </div>
              </div>
            </form>
          </div>
        </template>
      </base-card>
      <div class="mt-4">
        <base-card>
          <template #default>
            <div class="wrapper">
              <header><h2>Zdjęcia</h2></header>
              <form @submit.prevent="sendFiles">
                <div class="form-group">
                  <div class="form-control">
                    <input type="file" multiple @change="uploadFiles($event)" />
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-control">
                    <button type="submit" class="btn base">
                      Dodaj zdjęcia
                    </button>
                  </div>
                </div>
              </form>
              <div class="images">
                <div class="img" v-for="bg in bgs" :key="bg.id">
                  <div class="remove" @click="removePic(bg.id)"></div>
                  <img :src="apiUrl + '/' + bg.path" alt="bg" />
                </div>
              </div>
            </div>
          </template>
        </base-card>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ApartmentService from "../services/ApartmentService";
import { toast } from "vue3-toastify";
import axios from "axios";

export default {
  data() {
    return {
      apartment: {
        title: "",
        city: "",
        district: "",
        rooms: null,
        surface: null,
        price: null,
        rent: null,
        floorNumberInBuilding: null,
        floor: null,
        buildingType: "",
        avaiableFrom: null,
        deposit: null,
        link: "",
      },
      files: [],
      bgs: [],
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
  methods: {
    ...mapActions(["fetchSingleApartment", "logout"]),
    async removePic(bgId) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_API_URL}/apartments/${this.$route.params.id}/bg/${bgId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.getToken}`,
            },
          }
        );
        if (res.status === 200) {
          this.bgs = this.bgs.filter((bg) => bg.id !== bgId);
          toast.success("Zdjęcie usunięte");
        }
      } catch (err) {
        if (err.response.status === 403 || err.response.status === 401) {
          toast.error("Nieprawidłowy token.");
          this.logout();
        }
        toast.error("Nie udało się usunąć zdjęcia. Spróbuj później.");
      }
    },
    uploadFiles(event) {
      this.files = event.target.files;
    },
    async sendFiles() {
      let formData = new FormData();

      this.files.forEach((file) => {
        formData.append("files", file);
      });

      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/upload/${this.$route.params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.getToken}`,
            },
          }
        );
        toast.success("Pliki zostały załadowane na serwer.");
        const apartment = await this.fetchSingleApartment(
          this.$route.params.id
        );
        this.bgs = apartment.apartmentBackgrounds;
        this.mapApartments(apartment);
      } catch (err) {
        if (err.response.status === 403 || err.response.status === 401) {
          toast.error("Nieprawidłowy token.");
          this.logout();
        }
        toast.error("Nie udało się załadować plików, spróbuj później.");
      }
    },
    async saveApartment() {
      if (this.$route.params.id) {
        try {
          const res = await ApartmentService.updateApartment(
            this.$route.params.id,
            this.apartment
          );
          if (res.status === 200) {
            toast.success("Mieszkanie zaktualizowane");
          }
        } catch (err) {
          if (err.response.status === 401 || err.response.status == 403) {
            this.logout();
          }
          err.response.data.errors.forEach((error) => toast.error(error.msg));
        }
      } else {
        try {
          const res = await ApartmentService.addApartment(this.apartment);
          if (res.status === 201) {
            toast.success("Mieszkanie dodane.");
            this.$router.push("/dashboard");
          }
        } catch (err) {
          if (err.response.status === 401 || err.response.status == 403) {
            this.logout();
          }
          err.response.data.errors.forEach((error) => toast.error(error.msg));
        }
      }
    },
    mapApartments(apartment) {
      for (const key in apartment) {
        if (Object.keys(this.apartment).includes(key)) {
          if (key === "avaiableFrom") {
            this.apartment[key] = apartment[key].split("T")[0];
            continue;
          }
          this.apartment[key] = apartment[key];
        }
      }
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      try {
        const apartment = await this.fetchSingleApartment(
          this.$route.params.id
        );
        this.bgs = apartment.apartmentBackgrounds;
        this.mapApartments(apartment);
      } catch (err) {
        this.$router.push("/add");
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/_base.scss";

header {
  text-shadow: $multi;
  h2 {
    text-transform: uppercase;
    color: $primary;
    font-size: 1.75em;
    span {
      text-transform: capitalize;
      font-weight: normal;
      color: white;
    }
  }
}
.cols {
  display: flex;
  justify-content: space-between;
}
.pagination {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: $baseSize * 1.3;
  border-top: $black;
  div {
    &:first-child {
      span {
        &:first-child {
          font-weight: bold;
        }
        margin-right: 1rem;
      }
    }
  }
}
.mt-4 {
  margin-top: 2rem;
}
.my-4 {
  display: flex;
  justify-content: center;
  margin-top: 5.5rem;
  margin-bottom: 2rem;
}
.res {
  margin-bottom: 1rem;
  color: red;
  &.valid {
    color: green;
  }
}
.wrapper {
  width: auto;
  max-width: 960px;
  width: 90%;
  header {
    margin-top: 3rem;
    h1 {
      font-weight: normal;
      span {
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 8px solid $primary;
      }
    }
  }
  .check-group {
    flex-direction: column;
    border: none;
    margin-bottom: 2rem;
    div {
      margin-bottom: 1rem;
    }
  }
  form {
    padding-top: 1rem;
  }
  .form-group {
    display: flex;
    width: 100%;

    .form-control {
      width: 100%;
      margin-bottom: 1rem;
      margin-right: 1rem;
      & input,
      select,
      textarea {
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        color: $fontLight;
      }
      &.checkbox {
        display: flex;
        align-items: center;
        input {
          width: auto;
        }
      }
      textarea {
        resize: none;
        font-family: inherit;
      }
      &.mr {
        margin-right: 1rem;
      }
    }
  }
}
.images {
  display: flex;
  padding-bottom: 1rem;
  .img {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 1rem;
    margin-right: 1rem;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .remove {
      width: 2rem;
      height: 2rem;
      display: block;
      position: absolute;
      right: 1rem;
      top: 1rem;
      &:after {
        display: inline-block;
        content: "\00d7"; /* This will render the 'X' */
        cursor: pointer;
        font-size: 2rem;
        color: red;
      }
    }
  }
}
</style>